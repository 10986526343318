.channel-card {
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    position: relative;
    min-height: 280px;
}

.channel-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.card-actions {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    background-color: var(--semi-color-bg-1);
    border-top: 1px solid var(--semi-color-border);
    position: absolute;
    bottom: 0;
    left: 0;
}

.test-button {
    width: 100px;
    border-radius: 4px;
    border: 1px solid var(--semi-color-primary);
}

.channel-card .semi-card-body {
    padding: 16px;
    margin-bottom: 56px;
}

.channel-card .semi-card-meta {
    min-height: 48px;
}

/* 调整按钮区域的边距 */
.semi-card-body-actions {
    margin: 0 !important;
    padding: 0 !important;
}

/* 确保卡片底部按钮区域样式 */
.channel-card .semi-card-body-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--semi-color-bg-1);
    border-top: 1px solid var(--semi-color-border);
    padding: 12px 0 !important;
    margin: 0 !important;
}
